import { BASE_CDN_URL, BASE_IMAGE_URL } from '../constants';

export const SOCIAL_LINKS = [
  {
    url: 'https://podcasts.apple.com/us/podcast/nerdwallets-smart-money-podcast/id1256091892',
    imageSrc: `${BASE_CDN_URL}/podcast.svg`,
    imageAlt: 'Apple Podcasts icon',
    imageWidth: 48,
    imageHeight: 48,
    entityName: 'podcast_icon',
  },
  {
    url: 'https://www.instagram.com/nerdwallet',
    imageSrc: `${BASE_CDN_URL}/instagram.svg`,
    imageAlt: 'Instagram icon',
    imageWidth: 48,
    imageHeight: 48,
    entityName: 'ig_icon',
  },
  {
    url: 'https://www.tiktok.com/@nerdwallet',
    imageSrc: `${BASE_CDN_URL}/tiktok.svg`,
    imageAlt: 'TikTok icon',
    imageWidth: 48,
    imageHeight: 48,
    entityName: 'tiktok_icon',
  },
  {
    url: 'https://www.youtube.com/nerdwallet',
    imageSrc: `${BASE_CDN_URL}/youtube.svg`,
    imageAlt: 'YouTube icon',
    imageWidth: 48,
    imageHeight: 48,
    entityName: 'youtube_icon',
  },
];

export const SOCIAL_VIDEOS = [
  {
    source: {
      mp4: `${BASE_CDN_URL}/SalaryBudget-GenZ.MP4`,
      webm: `${BASE_CDN_URL}/SalaryBudget-GenZ.webm`,
    },
    poster: `${BASE_IMAGE_URL}/SalaryBudget-GenZ.png`,
    maxWidth: 367,
    maxHeight: 657,
    instagramLink: 'https://www.instagram.com/reel/DAlyjfupH6R/',
    hideOnMobile: false,
    hideOnTablet: true,
  },
  {
    source: {
      mp4: `${BASE_CDN_URL}/AmexGold+-+Response.MP4`,
      webm: `${BASE_CDN_URL}/AmexGold-Response.webm`,
    },
    poster: `${BASE_IMAGE_URL}/AmexGold-Response.png`,
    maxWidth: 367,
    maxHeight: 657,
    instagramLink: 'https://www.instagram.com/p/DBR7dlfp_IL/',
    hideOnMobile: true,
    hideOnTablet: false,
  },
  {
    source: {
      mp4: `${BASE_CDN_URL}/BackdoorRoth-Core.MP4`,
      webm: `${BASE_CDN_URL}/BackdoorRoth-Core.webm`,
    },
    poster: `${BASE_IMAGE_URL}/BackdoorRoth-Core.png`,
    maxWidth: 367,
    maxHeight: 657,
    instagramLink: 'https://www.instagram.com/p/DBzhoeMS4XA/',
    hideOnMobile: false,
    hideOnTablet: false,
  },
];
