import styles from './beluga-sticky-bar.module.scss';

export const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button className={styles.chevronDown} onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.21083 16.7752C5.92972 17.0564 5.92972 17.5121 6.21083 17.7932C6.49195 18.0744 6.94772 18.0744 7.22883 17.7932L11.9984 13.0237L16.8023 17.8275C17.0834 18.1087 17.5392 18.1087 17.8203 17.8275C18.1014 17.5464 18.1014 17.0907 17.8203 16.8095L13.0164 12.0057L17.7932 7.22883C18.0744 6.94772 18.0744 6.49195 17.7932 6.21083C17.5121 5.92972 17.0564 5.92972 16.7752 6.21083L11.9984 10.9877L7.25325 6.24249C6.97214 5.96138 6.51637 5.96138 6.23526 6.24249C5.95414 6.5236 5.95415 6.97938 6.23526 7.26049L10.9804 12.0057L6.21083 16.7752Z"
        fill="black"
      />
    </svg>
  </button>
);
