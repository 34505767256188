import React from 'react';
import { Box, Text } from '@nerdwallet/currency';
import styles from '../../../containers/front-page-redesign.module.scss';

const BelugaBanner = (): JSX.Element => (
  <Box className={styles.banner}>
    <Box className={styles.imageWrapper}>
      <div className={styles.image} />
    </Box>
    <Box className={styles.textWrapper}>
      <Text bold>
        So, you decided to take my genius advice. How can we help yooouuu?
      </Text>
    </Box>
  </Box>
);

export default BelugaBanner;
