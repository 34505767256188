import styles from './beluga-sticky-bar.module.scss';

export const ChevronRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.65385L1.15385 0.5L6.15385 5.5L1.15385 10.5L0 9.34615L3.84615 5.5L0 1.65385Z"
      fill="#006642"
    />
  </svg>
);
