const TestimonialsCardStar = ({ className = '' }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    className={className}
  >
    <path
      d="M16.3892 6.97833C16.7467 5.94993 18.2011 5.94993 18.5586 6.97833L21.1161 14.3359L28.9038 14.4946C29.9923 14.5168 30.4418 15.9 29.5742 16.5578L23.367 21.2637L25.6226 28.7193C25.9379 29.7614 24.7613 30.6163 23.8676 29.9944L17.4739 25.5453L11.0802 29.9944C10.1865 30.6163 9.00986 29.7614 9.32514 28.7193L11.5808 21.2637L5.37361 16.5578C4.506 15.9 4.95544 14.5168 6.04397 14.4946L13.8317 14.3359L16.3892 6.97833Z"
      fill="#00482F"
    />
  </svg>
);

export default TestimonialsCardStar;
