import React, { useState, useMemo } from 'react';
import Image from 'next/image';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import styles from './articles.module.scss';
import { CarouselCardProps } from './carousel-card';

export type ArticleCardProps = CarouselCardProps & {
  imageUrl: string;
};

const ArticleCard = ({
  id,
  title = '',
  imageUrl = '',
  authorImageUrl = '',
  articleLink = '',
  authorLink = '',
  author = '',
  date = '',
  readTime,
}: ArticleCardProps) => {
  const [formattedDate, setFormattedDate] = useState<string>('');
  const { trackSegmentTrackEvent } = useAnalytics();

  const handleAnalytics = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `breaking_news_feed_featured_${id}`,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      action: 'select',
    });
  };

  useMemo(() => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    setFormattedDate(formattedDate);
  }, [date]);

  return (
    <div className={styles.articleCard}>
      <div className={styles.articleContent}>
        <a
          href={articleLink}
          onClick={() => handleAnalytics()}
          className={styles.articleLinks}
        >
          <h3 className={styles.articleTitle}>{title}</h3>
        </a>
        <div className={styles.authorInfo}>
          {/* TODO: Add author image link */}
          <Image
            className={styles.authorImage}
            src={authorImageUrl}
            alt="author image"
            width={160}
            height={160}
          />
          <a href={authorLink} className={styles.articleLinks}>
            <p className={styles.authorName}>by {author}</p>
          </a>
        </div>
        <div className={styles.articleMeta}>
          <span className={styles.articleMetaText}>{formattedDate}</span>
          {readTime && readTime >= 1 ? (
            <span
              className={styles.articleMetaText}
            >{`${readTime} min read`}</span>
          ) : null}
        </div>
      </div>

      <a
        href={articleLink}
        onClick={() => handleAnalytics()}
        className={styles.articleImageLinkWrapper}
      >
        <Image src={imageUrl} alt="article image" width={770} height={462} />
      </a>
    </div>
  );
};

export default ArticleCard;
