export const QUIZ_LINKS = {
  CREDIT_CARD:
    'https://www.nerdwallet.com/recommend/CreditScore?trk_location=cc_super_bowl',
  PERSONAL_LOAN:
    'https://www.nerdwallet.com/prequalify/personal-loan/apply?affiliateName=NerdWallet&affiliateId=1333&subId1=organic&subId2=pl_superbowl&subId3=homepage',
  SMALL_BUSINESS_LOAN:
    'https://www.nerdwallet.com/redirect/d56b267e-073b-11ea-a8c5-179bb79b48c9',
};

export const INSURANCE_LINKS = {
  AUTO: 'https://www.nerdwallet.com/insurance/auto/match?qsSrcId=664395',
  LIFE: 'https://www.nerdwallet.com/insurance/life/match?offer_id=796',
  PROPERTY: 'https://www.nerdwallet.com/insurance/home/match?qsSrcId=662940',
};

export const MORTGAGE_LINKS = {
  PURCHASE: 'https://analyze.nerdwallet.com/mortgage/purchase/apply/property-type?affiliateName=NerdWallet&affiliateId=1333&subId1=organic&subId2=mortgage_refi_superbowl&subId3=homepage',
  REFINANCE: 'https://analyze.nerdwallet.com/mortgage/refinance/apply/property-type?affiliateName=NerdWallet&affiliateId=1333&subId1=organic&subId2=mortgage_refi_superbowl&subId3=homepage',
  HOME_EQUITY_LOAN: 'https://analyze.nerdwallet.com/home-equity?affiliateName=NerdWallet&affiliateId=1333&subId1=organic&subId2=mortgage_heloc_superbowl&subId3=homepage',
};