import styles from './quiz-style.module.scss';

type QuizButtonProps = {
  text: string;
  onClick?: () => void;
  icon?: () => JSX.Element | null;
};

function QuizButton({
  text,
  icon = () => null,
  onClick,
}: QuizButtonProps): JSX.Element {
  return (
    <button className={styles.quizButton} onClick={onClick}>
      {icon ? icon() : null}
      {text}
    </button>
  );
}

export default QuizButton;
