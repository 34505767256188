import Image from 'next/image';
import CardStar from './testimonials-card-star';
import styles from './testimonials.module.scss';

interface TestimonialCardProps {
  title?: string;
  subtitle?: string;
  titleStar?: boolean;
  subtitleStar?: boolean;
  backgroundType?: 'grid' | 'line';
}

function TestimonialCard({
  title = '',
  subtitle = '',
  titleStar = false,
  subtitleStar = false,
  backgroundType = 'line',
}: TestimonialCardProps) {
  const backgroundStyle = {
    grid: styles.testimonialsCardBackgroundGrid,
    line: styles.testimonialsCardBackgroundLine,
  };

  return (
    <div className={styles.testimonialsCard}>
      {backgroundType === 'grid' && (
        <Image
          src="https://www.nerdwallet.com/cdn/front-page-next/app-rating-card-bg-grid@3x.png"
          alt="Grid background"
          width={635}
          height={393}
        />
      )}
      {backgroundType === 'line' && (
        <Image
          src="https://www.nerdwallet.com/cdn/front-page-next/app-rating-card-bg-line@3x.png"
          alt="Line background"
          width={635}
          height={393}
        />
      )}
      <h4 className={styles.testimonialsCardTitle}>
        {title}
        {titleStar && <CardStar />}
      </h4>
      <p className={styles.testimonialsCardSubtitle}>
        {subtitle}
        {subtitleStar && <CardStar />}
      </p>
    </div>
  );
}

export default TestimonialCard;
