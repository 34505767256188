type CarouselArrowProps = {
  type?: 'prev' | 'next';
};

const TestimonialsCarouselArrow = ({ type = 'next' }: CarouselArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    style={{ transform: type === 'prev' ? 'rotate(180deg)' : '' }}
  >
    <path
      d="M1.5 0.984863L7.61111 7.09597L1.5 13.2071"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TestimonialsCarouselArrow;