import styles from './nerdwallet-plus.module.scss'
import { BASE_IMAGE_URL } from "../constants"
import {parseISO, format} from 'date-fns';

export const FOLDER_COLORS = {
  DEFAULT: '#E8FDAC',
  TSY_HOUSE_AD: '#E3FAF2'
}

export const COPY = {
  DEFAULT :{
    title: 'Get cash rewards for being smart with your finances',
    subtitle: 'It’s free to collect points for smart moves like monitoring your credit score. Upgrade to NerdWallet+ for $49/year to earn up to $599/year in cash rewards.*',
    ctaCopy: 'START FOR FREE',
    terms: '*$599 is max rewards value if you join NerdWallet+ for $49/year.',
    images: {
      desktop: {
        src: `${BASE_IMAGE_URL}/heroes/NPlus-599-375.png`,
        width: 611,
        height: 445,
      },
      tablet: {
        src: `${BASE_IMAGE_URL}/heroes/NPlus-599-768.png`,
        width: 365,
        height: 435
      },
      mobile: {
        src: `${BASE_IMAGE_URL}/heroes/NPlus-599-1440.png`,
        width: 343,
        height: 320,
      },
    }
  },
  TSY_HOUSE_AD: {
    title: (apy?: number) => {
      if (apy !== undefined) {
        return <span>Earn {apy.toFixed(2)+'%'} APY by investing in U.S. Treasury Bills*</span>
      }

      return <span>Earn <span className={styles.shimmerApy}></span> APY by investing in U.S. Treasury Bills*</span>
    },
    subtitle: 'Maximize your cash by investing in low-risk, government-backed T-Bills. All the work is done for you — just make the deposit and watch your money grow.',
    ctaCopy: 'LEARN MORE',
    terms: (date?: string) => {
      if (date) {
        const parsedDate = parseISO(date)
        const formattedDate = format(parsedDate, 'MM/dd/yyyy')
        return `*Rate when held to maturity for a T-Bill maturing in one month as of ${formattedDate}. Rate shown is subject to price fluctuations.`
      }
    },
    images: {
      desktop: {
        src: 'https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/front-page-next/tsy-house-ad-desktop-updated-2x.png',
        width: 563,
        height: 410,
      },
      tablet: {
        src: 'https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/front-page-next/tsy-house-ad-tablet-updated-2x.png',
        width: 320,
        height: 366,
      },
      mobile: {
        src: 'https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/front-page-next/tsy-house-ad-mobile-updated-2x.png',
        width: 343,
        height: 282,
      },
    }
  }
}
