'use client';
/**
 * Dependencies
 */
import React from 'react';
import ShoppingModule from '~/app/components/front-page-redesign/shopping-module';
import NerdWalletPlus from '~/app/components/front-page-redesign/nerdwallet-plus/nerdwallet-plus';
import SocialModule from '~/app/components/front-page-redesign/social-module/social-module';
import { Box, CenteredContainer, Text } from '@nerdwallet/currency';
import styles from './front-page-redesign.module.scss';
import {
  StructuredContentProvider,
  createClient,
} from '@nerdwallet/structured-content-core';
import components from '~/app/lib/sc-components';
import dynamic from 'next/dynamic';
import schema from '@nerdwallet/structured-content/schema';
import SegmentTracking from '~/app/lib/segment/SegmentTracking';
import BounceTracking from '~/app/components/bounce-tracking';
import { useInitializeGtm } from '~/app/lib/initializeGtm';
import { getPageTaxonomy } from '~/app/lib/page-taxonomy';
import { usePathname } from 'next/navigation';
import Quiz from '~/app/components/front-page-redesign/quiz';
import Testimonials from '~/app/components/front-page-redesign/testimonials';
import Articles from '~/app/components/front-page-redesign/articles';
import { useExperiment } from '@nerdwallet/features';
import { EXPERIMENTS } from '~/app/experiments';
import BelugaStickyBar from '../components/front-page-redesign/beluga-sticky-bar';
import BelugaBanner from '../components/front-page-redesign/beluga-banner/beluga-banner';

const BELUGA_EXPERIMENT = EXPERIMENTS.belugaExperiment.id;
const { DIRECTION_ONE, DIRECTION_TWO } = EXPERIMENTS.belugaExperiment.variants;

const FrontPageRedesign = () => {
  // const isAuthenticated = useIsAuthenticated();
  const client = createClient({
    components,
    schema,
    codesplittingFunction: (asyncImport, exportName) =>
      dynamic(() =>
        asyncImport.then((mod) => ({
          default: mod[exportName],
        })),
      ),
  });

  useInitializeGtm({ pageTaxonomy: getPageTaxonomy(usePathname()) ?? [] });

  const { assignedVariantName } = useExperiment({
    testName: BELUGA_EXPERIMENT,
    options: {
      ssr: false,
    },
  });

  const isControl = assignedVariantName === 'control';
  const isDirectionOne = assignedVariantName === DIRECTION_ONE;
  const isDirectionTwo = assignedVariantName === DIRECTION_TWO;

  return (
    <React.Fragment>
      <BounceTracking />
      <SegmentTracking segmentPageName="homepage">
        <StructuredContentProvider client={client}>
          <Box className={styles.mainWrapper}>
            <CenteredContainer
              className={
                isControl
                  ? styles.shoppingModuleWrapper
                  : styles.shoppingModuleWithoutBanner
              }
            >
              {isControl ? <BelugaBanner /> : null}
              <Box display="flex" flexDirection="column">
                <Box className={styles.heading}>
                  <Text component="h1" bold>
                    <span className={styles.titleSize}>
                      Letting NerdWallet do the work for you is genius
                    </span>
                  </Text>
                  <div className={styles.subHeading}>
                    <Text component="h2" size="large">
                      Compare our top picks side by side. Click a tab to get
                      started.
                    </Text>
                  </div>
                </Box>
                <ShoppingModule isDirectionTwo={isDirectionTwo} />
              </Box>
            </CenteredContainer>
            <Quiz isDirectionTwo={isDirectionTwo} />
            <NerdWalletPlus showNerdWalletPlus showBestOfAwards={false} />
            <SocialModule />
            <Articles />
            <Testimonials />
            {isDirectionOne ? <BelugaStickyBar /> : null}
          </Box>
        </StructuredContentProvider>
      </SegmentTracking>
    </React.Fragment>
  );
};

export default FrontPageRedesign;
