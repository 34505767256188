import { useEffect, useState, useRef } from 'react';
import useAnalytics from '~/app/lib/segment/useAnalytics';

const INITIAL_SCROLL_POSITION = 50;

export const useBelugaStickyBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showBelugaBanner, setShowBelugaBanner] = useState(true);
  const isIOSChrome = useRef(false);
  const isIOSFirefox = useRef(false);
  const { trackSegmentTrackEvent } = useAnalytics();

  function handleExpandBanner() {
    if (isScrolled) {
      setIsScrolled(false);
    }
  }

  function handleCollapseBanner() {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: 'hp_sticky_banner_collapse',
      location: 'homepage',
      sub_location: 'bottom',
    });
    if (!isScrolled) {
      setIsScrolled(true);
    }
  }

  function handleHideBelugaBanner() {
    setShowBelugaBanner(false);
  }

  async function handleCTAClick() {
    await trackSegmentTrackEvent('Element Interaction', {
      entity_name: 'hp_sticky_banner_link_click',
      location: 'homepage',
      sub_location: 'bottom',
    });
  }

  useEffect(() => {
    isIOSChrome.current = /CriOS/.test(window.navigator.userAgent);
    isIOSFirefox.current = /FxiOS/.test(window.navigator.userAgent);

    const handleScroll = () => {
      if (window.scrollY > INITIAL_SCROLL_POSITION) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    isScrolled,
    handleExpandBanner,
    handleCollapseBanner,
    showBelugaBanner,
    handleHideBelugaBanner,
    handleCTAClick,
    isIOSChromeOrFirefox: isIOSChrome.current || isIOSFirefox.current,
  };
};
