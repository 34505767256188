import Container from '@nerdwallet/react-container';
import { Divider } from '@nerdwallet/currency/components/Divider';
import { Li } from '@nerdwallet/currency/components/Li';
import { List } from '@nerdwallet/currency/components/List';
import { NativeType as Type } from '@nerdwallet/currency/components/NativeType';
import {
  BUTTON,
  CONTAINER,
  DIVIDER,
  DOCUMENT,
  INSTAGRAM,
  LINK,
  LIST,
  LISTITEM,
  TYPE,
  WPHOUSEAD,
} from '@nerdwallet/structured-content/types';

export default {
  [BUTTON]: () => [
    import('@nerdwallet/structured-content/dist/components/button'),
  ],
  [CONTAINER]: () => Container,
  [DIVIDER]: () => Divider,
  [DOCUMENT]: () => [
    import('@nerdwallet/structured-content/dist/components/document'),
  ],
  [LINK]: () => [import('@nerdwallet/structured-content/dist/components/link')],
  [LIST]: () => List,
  [LISTITEM]: () => Li,
  [TYPE]: () => Type,
  [INSTAGRAM]: () =>
    import('@nerdwallet/structured-content/dist/components/instagram'),
  [WPHOUSEAD]: () => [
    import('@nerdwallet/structured-content/dist/components/wp-house-ad'),
  ],
};
