import React, { ReactNode } from 'react';
import { useTabs } from './TabsContext';
import styles from './Tabs.module.scss';

interface TabPanelProps {
  name: string;
  children: ReactNode;
}

const TabPanel = ({ name, children }: TabPanelProps) => {
  const { selectedTab } = useTabs();

  // If the tab is not selected, don't render it (this avoid fetching iamges for all tabs)
  if (selectedTab !== name) return null;

  return (
    <div
      role="tabpanel"
      className={styles.tabPanel}
      style={{ display: 'block' }}
    >
      {children}
    </div>
  );
};

export default TabPanel;
