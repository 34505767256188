import { BASE_IMAGE_URL } from './app/components/front-page-redesign/constants';

export default function customImageLoader({ src, width, quality }) {
  // Optimizing Cloudflare CDN images according to the width and quality.
  if (src.startsWith(BASE_IMAGE_URL) && width !== undefined) {
    const imagePath = src.split(BASE_IMAGE_URL)[1];
    return `https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80,width=${width}/cdn/apps/prod/front-page/images/homepage/superbowl${imagePath}?w=${width}&q=${quality || 80}`;
  }

  if (src.startsWith('http')) {
    if (width !== undefined) {
      const url = new URL(src);
      url.searchParams.set('w', width);
      url.searchParams.set('q', quality || 75);
      return url;
    }
    return src;
  }

  // Use NextJS image optimization API for relative urls.
  return `/front-page-images/_next/image?url=${src}&w=${width}&q=${quality || 75} `;
}
