import React, { useState, useMemo } from 'react';
import Image from 'next/image';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import styles from './articles.module.scss';

export type CarouselCardProps = {
  id: string;
  title: string;
  authorImageUrl: string;
  articleLink: string;
  authorLink: string;
  author: string;
  date: string;
  readTime?: number;
};

const CarouselCard = ({
  id,
  title,
  author,
  authorImageUrl,
  date,
  readTime,
  articleLink,
  authorLink,
}: CarouselCardProps) => {
  const [formattedDate, setFormattedDate] = useState<string>('');
  const { trackSegmentTrackEvent } = useAnalytics();

  useMemo(() => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    setFormattedDate(formattedDate);
  }, [date]);

  const handleAnalytics = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `breaking_news_feed_sidebar_${id}`,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      action: 'select',
    });
  };

  return (
    <div className={styles.carouselCard}>
      <div className={styles.carouselContent}>
        <a
          href={articleLink}
          onClick={() => handleAnalytics()}
          className={styles.articleLinks}
        >
          <h3 className={styles.carouselTitle}>{title}</h3>
        </a>
        <div className={styles.carouselAuthorInfo}>
          <Image
            className={styles.authorImage}
            src={authorImageUrl}
            alt="author image"
            width={160}
            height={160}
          />
          <a href={authorLink} className={styles.articleLinks}>
            <p className={styles.authorName}>by {author}</p>
          </a>
        </div>
        <div className={styles.carouselArticleMeta}>
          <span className={styles.carouselArticleMetaText}>
            {formattedDate}
          </span>
          {readTime && readTime >= 1 ? (
            <span
              className={styles.carouselArticleMetaText}
            >{`${readTime} min read`}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
