import styles from './beluga-sticky-bar.module.scss';

export const ChevronDownButton = ({ onClick }: { onClick: () => void }) => (
  <button className={styles.chevronDown} onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.336 16.2803C11.6289 16.5732 12.1037 16.5732 12.3966 16.2803L19.2803 9.39663C19.5732 9.10374 19.5732 8.62886 19.2803 8.33597C18.9874 8.04308 18.5126 8.04308 18.2197 8.33597L11.861 14.6946L5.49441 8.32802C5.20297 8.03659 4.73047 8.03659 4.43904 8.32802C4.1476 8.61945 4.1476 9.09196 4.43904 9.38339L11.336 16.2803Z"
        fill="black"
      />
    </svg>
  </button>
);
