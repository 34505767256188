import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

type TabsContextType = {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
};

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProviderProps {
  defaultValue: string;
  children: ReactNode;
}

export const TabsProvider = ({ defaultValue, children }: TabsProviderProps) => {
  const [selectedTab, setSelectedTab] = useState(defaultValue);

  return (
    <TabsContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = (): TabsContextType => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
};
