import React from 'react';
import StructuredContent from '@nerdwallet/structured-content-core';
import appConfig from '@nerdwallet/app-config';

interface Props {
  instagramURL: string;
}

const InstagramPlayer = ({ instagramURL }: Props): JSX.Element => (
  <StructuredContent>
    {{
      type: 'Instagram',
      version: '1.0.0',
      props: {
        url: instagramURL,
        maxWidth: 650,
        hideCaptions: true,
      },
    }}
  </StructuredContent>
);

export default InstagramPlayer;
