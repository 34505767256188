import React from 'react';
import { useTabs } from './TabsContext';
import styles from './Tabs.module.scss';
import { Accent } from '@nerdwallet/currency';
import classNames from 'classnames';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '../../../../lib/analytics/analytics-constants';
import useAnalytics from '../../../../lib/segment/useAnalytics';
import { SELECTED_TAB_Z_INDEX_VALUES } from './constants';

interface TabProps {
  name: string;
  label: string;
  backgroundColor: string;
}

const Tab = ({ name, label, backgroundColor }: TabProps) => {
  const { selectedTab, setSelectedTab } = useTabs();
  const { trackSegmentTrackEvent } = useAnalytics();
  const entityName = `${analyticsBase.entity_names.tabs}_${name}`;
  const handleClick = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      interaction_type: 'select',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
    });
    setSelectedTab(name);
  };

  const isSelected = selectedTab === name;
  // Getting tab zIndex value based on current selected tab.
  const zIndex = SELECTED_TAB_Z_INDEX_VALUES[selectedTab][name];

  return (
    <div
      className={styles.tabWrapper}
      style={{
        zIndex,
      }}
    >
      <button
        role="tab"
        aria-selected={isSelected}
        onClick={handleClick}
        className={classNames([styles.tab, { [styles.active]: isSelected }])}
        style={{
          backgroundColor,
        }}
      >
        <Accent size="small">{label}</Accent>
      </button>
    </div>
  );
};

export default Tab;
