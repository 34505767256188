export const BELUGA_EXPERIMENT = {
  belugaExperiment: {
    id: 'bst-homepage-beluga',
    variants: {
      DIRECTION_ONE: 'direction-1',
      DIRECTION_TWO: 'direction-2',
    },
  },
};

export const BANNER_EXPERIMENT = {
  bannerExperiment: {
    id: 'EXPERT-1712_homepage_remove_banner_for_mobile',
    variants: {
      VARIANT: 'treatment',
    },
  },
};

export const CHATBOT_VISIBLE_EXPERIMENT = {
  chatbotVisibleExperiment: {
    id: 'cad-975_chatbot_front_page',
    variants: {
      VARIANT: 'treatment',
    },
  },
};

export const VERTICAL_COMPARISON_TAB_EXPERIMENT = {
  verticalComparisonTabExperiment: {
    id: 'EXPLAT-1627_homepage_remove_explore_button',
    variants: {
      VARIANT: 'treatment',
    },
  },
};

export const HEADLINE_EXPERIMENT = {
  headlineExperiment: {
    id: 'EXPERT-1791_homepage_multi-headline_experiment',
    variants: {
      VARIANT_SIDE: 'treatment-by-your-side',
      VARIANT_TODAY: 'treatment-start-today',
      VARIANT_COMPARE: 'treatment-compare-find',
    },
  },
};

export const NWPLUS_CAMPAIGN_EXPERIMENT = {
  nwplusCampaign: {
    id: 'EXPERT-2082_updated_nw_plus_campaign_module_cash',
    variants: {
      VARIANT: 'treatment',
    },
  },
};

export const EXPERIMENTS = {
  ...BELUGA_EXPERIMENT,
  ...BANNER_EXPERIMENT,
  ...CHATBOT_VISIBLE_EXPERIMENT,
  ...VERTICAL_COMPARISON_TAB_EXPERIMENT,
  ...HEADLINE_EXPERIMENT,
  ...NWPLUS_CAMPAIGN_EXPERIMENT,
};
