type Props = {
  className?: string | undefined;
  onClick?: () => void;
};

const QuizResetIcon = ({
  className = undefined,
  onClick = () => {},
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M1.54981 4.7966C2.72859 2.42759 5.1741 0.799805 7.9998 0.799805C11.703 0.799805 14.7532 3.5956 15.1549 7.19164M0.843749 8.7998C1.24169 12.3997 4.29376 15.1998 7.9998 15.1998C10.826 15.1998 13.272 13.5714 14.4505 11.2016M0.799805 1.5998V5.1998H4.3998M15.1998 14.7998V11.1998H11.5998"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default QuizResetIcon;
