import { BASE_IMAGE_URL } from '../constants';
import Image from 'next/image';
import classNames from 'classnames';
import styles from './beluga-sticky-bar.module.scss';
import { ChevronRightIcon } from './chevron-right-Icon';
import { CloseButton } from './close-button';
import { ChevronDownButton } from './chevron-down-button';
import { useBelugaStickyBar } from './use-beluga-sticky-bar';
import SegmentImpression from '../../segment-impression';
import { Link } from '@nerdwallet/currency';

const BelugaStickyBar = () => {
  const {
    isScrolled,
    showBelugaBanner,
    handleExpandBanner,
    handleCollapseBanner,
    handleHideBelugaBanner,
    handleCTAClick,
    isIOSChromeOrFirefox,
  } = useBelugaStickyBar();

  if (!showBelugaBanner) {
    return null;
  }

  return (
    <div
      onClick={handleExpandBanner}
      className={classNames(styles.container, {
        [styles.scrolled]: isScrolled,
        [styles.isIOSChromeOrFirefox]: isIOSChromeOrFirefox,
      })}
    >
      <div
        className={classNames(styles.belugaContainer, {
          [styles.belugaContainerCollapsed]: isScrolled,
        })}
      >
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_sticky_banner_view',
            location: 'homepage',
            sub_location: 'bottom',
          }}
        >
          <Image
            className={styles.belugaImageDesktop}
            src={`${BASE_IMAGE_URL}/25Q1_PMK_Homepage-Beluga-Placement-Redesign_Direction-1_Desktop.png`}
            alt="beluga"
            width={230}
            height={96}
          />
          <Image
            className={styles.belugaImageTablet}
            src={`${BASE_IMAGE_URL}/25Q1_PMK_Homepage-Beluga-Placement-Redesign_Direction-1_Tablet.png`}
            alt="beluga"
            width={174}
            height={86}
          />
        </SegmentImpression>
      </div>
      <div
        className={classNames(styles.text, {
          [styles.textContainerCollapsed]: isScrolled,
        })}
      >
        <h5 className={styles.desktopText}>Get the NerdWallet app —&nbsp;</h5>
        <h5 className={styles.desktopText}>
          it tracks your finances for youuuuu
        </h5>
        <h5 className={styles.MobileText}>
          it tracks your finances for youuuuu.
        </h5>
        <Link
          href="https://click.nerdwallet.com/3687710914/38eyx5gc?af_qr=true"
          onClick={handleCTAClick}
          className={classNames(styles.tabletCta, {
            [styles.tabletCtaCollapsed]: isScrolled,
          })}
        >
          Get the NerdWallet app <ChevronRightIcon />
        </Link>
      </div>
      <div
        className={classNames(styles.QRCodeImageContainer, {
          [styles.QRcodeContainerCollapsed]: isScrolled,
        })}
      >
        <Image
          alt="QR code for the NerdWallet app"
          height={77}
          width={77}
          src="https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/front-page-next/nw-app-download-qr-code-app.png"
        />
      </div>
      <div
        className={classNames(styles.closeButtonContainer, {
          [styles.adjustCloseIcon]: isScrolled,
        })}
      >
        {isScrolled ? (
          <CloseButton onClick={handleHideBelugaBanner} />
        ) : (
          <ChevronDownButton onClick={handleCollapseBanner} />
        )}
      </div>
      <div
        className={classNames(styles.belugaMobileContainer, {
          [styles.belugaMobileSlideIn]: isScrolled,
        })}
      >
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_sticky_banner_view',
            location: 'homepage',
            sub_location: 'bottom',
          }}
        >
          <Image
            alt="Beluga mobile banner"
            height={76}
            width={85}
            src={`${BASE_IMAGE_URL}/25Q1_PMK_Homepage-Beluga-Placement-Redesign_Direction-1_Mobile.png`}
          />
        </SegmentImpression>
      </div>
    </div>
  );
};

export default BelugaStickyBar;
