import React from 'react';
import Image from 'next/image';
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  SHOPPING_TABS,
  SHOPPING_TABS_CONTENT,
} from './ShoppingTabs';
import { Button } from '@nerdwallet/currency';
import styles from './ShoppingModule.module.scss';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import useAnalytics from '~/app/lib/segment/useAnalytics';

type ShoppingModuleProps = {
  isDirectionTwo?: boolean;
};

const ShoppingModule = ({
  isDirectionTwo = false,
}: ShoppingModuleProps): JSX.Element => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const trackAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      interaction_type: 'select',
    });
  };

  return (
    <div>
      <Tabs defaultValue={SHOPPING_TABS[0]}>
        <TabList>
          {SHOPPING_TABS.map((tab, index) => (
            <Tab
              name={tab}
              label={SHOPPING_TABS_CONTENT[tab].label}
              backgroundColor={SHOPPING_TABS_CONTENT[tab].backgroundColor}
              key={`${tab}_${index}`}
            />
          ))}
        </TabList>
        {SHOPPING_TABS.map((tab, index) => {
          const { backgroundColor, mainText, cta, images } =
            SHOPPING_TABS_CONTENT[tab];
          const primaryEntityName = `${analyticsBase.entity_names.primary_cta}_${tab}`;
          return (
            <TabPanel name={tab} key={`${tab}_content_${index}`}>
              <div
                className={styles.card}
                style={{
                  backgroundColor,
                }}
              >
                <div className={styles.cardTextAndCTA}>
                  <span className={styles.cardText}>{mainText}</span>
                  <Button
                    primary
                    href={cta?.url}
                    onClick={() => trackAnalytics(primaryEntityName)}
                  >
                    {cta?.label}
                  </Button>
                </div>
                <div className={styles.desktopImage}>
                  <Image
                    src={images?.desktopURL}
                    alt={images?.alt}
                    width={536}
                    height={400}
                  />
                </div>
                <div className={styles.tabletImage}>
                  <Image
                    src={images?.tabletURL}
                    alt={images?.alt}
                    width={1279}
                    height={549}
                  />
                </div>
                <div className={styles.mobileImage}>
                  <Image
                    src={
                      isDirectionTwo ? images?.mobileAltURL : images?.mobileURL
                    }
                    alt={images?.alt}
                    width={375}
                    height={isDirectionTwo ? 180 : 346}
                  />
                </div>
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ShoppingModule;
