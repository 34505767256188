import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Tabs.module.scss';
import { useTabs } from './TabsContext';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '../../../..//lib/analytics/analytics-constants';
import SegmentImpression from '../../../../components/segment-impression';

interface TabListProps {
  children: ReactNode;
}

const TabList = ({ children }: TabListProps) => {
  const { selectedTab } = useTabs();
  const entityName = `${analyticsBase.entity_names.tabs}_${selectedTab}`;

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: entityName,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      }}
    >
      <div className={styles.tabsList} role="tablist">
        {children}
      </div>
    </SegmentImpression>
  );
};

export default TabList;
