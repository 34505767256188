export const testimonials = [
  {
    id: 1,
    text: 'Right away I felt more secure and had more understanding of my finances.',
    author: 'Michaela',
  },
  {
    id: 2,
    text: 'Great free option to track spending.',
    author: 'Michael',
  },
  {
    id: 3,
    text: 'Fast and easy. I love this app.',
    author: 'Deborah',
  },
  {
    id: 4,
    text: 'I really enjoyed how helpful NerdWallet was in finding me my best loan offer.',
    author: 'Jacob',
  },
  {
    id: 5,
    text: 'The easiest and fastest way to get your credit score free.',
    author: 'Thomas',
  },
];
