import React from 'react';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import { CenteredContainer } from '@nerdwallet/currency';
import { SOCIAL_LINKS, SOCIAL_VIDEOS } from './constants';
import Image from 'next/image';
import styles from './social-module.module.scss';
import classNames from 'classnames';
import SegmentImpression from '~/app/components/segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import InstagramPlayer from '../instagram-player/instagram-player';

interface Props {}

const SocialModule = (props: Props): JSX.Element => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleAnalyticsClick = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `${entityName}_click`,
    });
  };

  return (
    <div className={styles.wrapper}>
      <FolderTabContainer
        tabBackgroundColor="#006642"
        contentBackgroundColor="#006642"
      >
        <div className={styles.container}>
          <CenteredContainer>
            <div className={styles.heading}>
              <span className={styles.title}>Follow our Nerds everywhere</span>
              <span
                className={classNames([styles.subTitle, styles.hideOnMobile])}
              >
                Need more nerdy info on today’s best financial products and
                trending topics? Listen to our Smart Money podcast, check us out
                on social, and subscribe to our YouTube channel.
              </span>
              <span
                className={classNames([styles.subTitle, styles.hideOnDesktop])}
              >
                Get more nerdy info on today’s best financial products and
                trending topics.
              </span>
            </div>
            <div className={styles.socialIcons}>
              {SOCIAL_LINKS.map((link, index) => (
                <a
                  href={link.url}
                  target="_blank"
                  onClick={() => handleAnalyticsClick(link.entityName)}
                  key={`link_${index}`}
                >
                  <SegmentImpression
                    eventName="Element Impression"
                    eventProps={{
                      entity_name: `${link.entityName}_view`,
                    }}
                  >
                    <Image
                      src={link.imageSrc}
                      alt={link.imageAlt}
                      width={link.imageWidth}
                      height={link.imageHeight}
                    />
                  </SegmentImpression>
                </a>
              ))}
            </div>
            <div className={styles.socialVideos}>
              {SOCIAL_VIDEOS.map((video, index) => (
                <div
                  key={`social_video_${index}`}
                  className={classNames([
                    styles.instagramEmbed,
                    { [styles.hideOnMobile]: video.hideOnMobile },
                    { [styles.hideOnTablet]: video.hideOnTablet },
                  ])}
                >
                  <SegmentImpression
                    eventName="Element Impression"
                    eventProps={{
                      entity_name: `ig_video_${index + 1}_view`,
                    }}
                  >
                    <InstagramPlayer instagramURL={video.instagramLink} />
                  </SegmentImpression>
                </div>
              ))}
            </div>
          </CenteredContainer>
        </div>
      </FolderTabContainer>
    </div>
  );
};

export default SocialModule;
