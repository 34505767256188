const QuizLifeInsuranceIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.6865 52.1456C28.6578 52.1471 28.629 52.1471 28.6003 52.1456L28.3907 52.1215C28.3507 52.117 28.3112 52.1092 28.2724 52.0985C8.53029 46.4947 6.67339 18.5249 6.50093 12.9742C6.49351 12.7555 6.53045 12.5376 6.60955 12.3335C6.68865 12.1295 6.80825 11.9436 6.96115 11.7871C7.28336 11.4723 7.71392 11.2929 8.16432 11.2857C19.9414 11.3529 25.5211 6.73774 27.374 4.7495L27.4462 4.67329C27.6128 4.50357 27.812 4.36941 28.0319 4.27893C28.2519 4.18845 28.4878 4.14354 28.7256 4.1469C28.9725 4.1518 29.2159 4.20625 29.4414 4.307C29.6668 4.40776 29.8697 4.55278 30.0381 4.73345C31.893 6.72571 37.4687 11.3238 49.2608 11.2717C49.4845 11.269 49.7064 11.3115 49.9132 11.3968C50.1199 11.4821 50.3073 11.6084 50.464 11.768C50.6165 11.9235 50.7358 12.1084 50.8146 12.3114C50.8934 12.5145 50.93 12.7315 50.9222 12.9491C50.9011 13.651 50.835 15.1369 50.6284 17.1432C50.6198 17.2426 50.5914 17.3392 50.5451 17.4275C50.4987 17.5158 50.4353 17.594 50.3584 17.6575C50.2816 17.7211 50.1929 17.7687 50.0974 17.7976C50.002 17.8265 49.9018 17.8362 49.8026 17.826C49.7034 17.8158 49.6072 17.786 49.5196 17.7383C49.432 17.6907 49.3548 17.626 49.2924 17.5482C49.2301 17.4704 49.1838 17.3809 49.1564 17.2851C49.1289 17.1892 49.1208 17.0888 49.1325 16.9898C49.333 15.0306 49.3982 13.5888 49.4192 12.905C49.3721 12.8048 49.338 12.7616 49.2718 12.7757C36.9022 12.8378 30.9354 7.8948 28.9472 5.75716C28.9162 5.72439 28.8789 5.69825 28.8376 5.68032C28.7962 5.6624 28.7516 5.65306 28.7066 5.65288C28.6403 5.65255 28.5764 5.67767 28.5281 5.72307C26.4907 7.90984 20.538 12.8419 8.16031 12.7917C8.09815 12.7647 8.06005 12.8198 8.04 12.8368C8.17235 18.2882 9.94503 45.2765 28.6263 50.6246C40.9258 47.0502 45.9982 34.0809 48.0867 23.8128C48.1266 23.6174 48.2425 23.4458 48.4089 23.3358C48.5753 23.2258 48.7786 23.1864 48.974 23.2263C49.1695 23.2662 49.3411 23.3821 49.4511 23.5485C49.5611 23.7149 49.6005 23.9182 49.5606 24.1136C47.3808 34.8329 42.0257 48.3927 28.894 52.1135C28.8267 52.1339 28.7568 52.1447 28.6865 52.1456Z"
      fill="#008254"
    />
    <path
      d="M31.7145 36.9966H25.476C25.2766 36.9966 25.0853 36.9174 24.9443 36.7764C24.8033 36.6353 24.724 36.4441 24.724 36.2446V31.2314H19.7198C19.5204 31.2314 19.3291 31.1522 19.1881 31.0111C19.0471 30.8701 18.9678 30.6789 18.9678 30.4794V24.2399C18.9678 24.0405 19.0471 23.8492 19.1881 23.7082C19.3291 23.5672 19.5204 23.488 19.7198 23.488H24.724V18.4747C24.724 18.2753 24.8033 18.084 24.9443 17.943C25.0853 17.802 25.2766 17.7227 25.476 17.7227H31.7145C31.9139 17.7227 32.1052 17.802 32.2462 17.943C32.3872 18.084 32.4665 18.2753 32.4665 18.4747V23.488H37.4707C37.6701 23.488 37.8614 23.5672 38.0024 23.7082C38.1434 23.8492 38.2227 24.0405 38.2227 24.2399V30.4824C38.2227 30.6819 38.1434 30.8731 38.0024 31.0142C37.8614 31.1552 37.6701 31.2344 37.4707 31.2344H32.4635V36.2476C32.4627 36.446 32.3835 36.6361 32.2432 36.7764C32.1029 36.9167 31.9129 36.9958 31.7145 36.9966ZM26.231 35.4926H30.9625V30.4794C30.9625 30.28 31.0417 30.0887 31.1828 29.9477C31.3238 29.8067 31.5151 29.7274 31.7145 29.7274H36.7187V24.9919H31.7145C31.5151 24.9919 31.3238 24.9127 31.1828 24.7717C31.0417 24.6307 30.9625 24.4394 30.9625 24.2399V19.2267H26.228V24.2399C26.228 24.4394 26.1488 24.6307 26.0078 24.7717C25.8667 24.9127 25.6755 24.9919 25.476 24.9919H20.4718V29.7304H25.476C25.6755 29.7304 25.8667 29.8097 26.0078 29.9507C26.1488 30.0917 26.228 30.283 26.228 30.4824L26.231 35.4926Z"
      fill="#008254"
    />
  </svg>
);

export default QuizLifeInsuranceIcon;