import React from 'react';
import styles from './folder-tab-container.module.scss';
import classNames from 'classnames';

type FolderTabContainerProps = {
  children: React.ReactNode;
  tabBackgroundColor?: string;
  contentBackgroundColor?: string;
  showBorderBottom?: boolean;
  roundedCorners?: boolean;
  contentClassName?: string;
  negativeTopMarginValue?: number;
  folderTabWidthPercentage?: number;
  smallCorners?: boolean;
  isFolderBackground?: boolean;
  hideOnMobile?: boolean;
};

function FolderTabContainer({
  children,
  contentClassName,
  tabBackgroundColor = '#fff',
  contentBackgroundColor = '#fff',
  showBorderBottom = false,
  roundedCorners = false,
  negativeTopMarginValue = 0,
  folderTabWidthPercentage = 20,
  smallCorners = false,
  isFolderBackground = false,
  hideOnMobile = false,
}: FolderTabContainerProps): JSX.Element {
  return (
    <div
      className={classNames([
        styles.container,
        { [styles.backgroundPosition]: isFolderBackground },
        { [styles.hideOnMobile]: hideOnMobile },
      ])}
    >
      <div
        className={classNames([
          styles.tab,
          { [styles.roundedCorners]: roundedCorners },
          { [styles.folderBackground]: isFolderBackground },
        ])}
        style={{
          backgroundColor: tabBackgroundColor,
          marginTop: `-${negativeTopMarginValue}px`,
          width: `${folderTabWidthPercentage}%`,
        }}
      />
      <div
        className={classNames([
          styles.tabSkew,
          { [styles.folderBackground]: isFolderBackground },
        ])}
        style={{
          backgroundColor: tabBackgroundColor,
          width: `${folderTabWidthPercentage + 2}%`,
        }}
      />
      <div
        className={classNames([
          styles.tabContent,
          contentClassName,
          { [styles.tabContentBorderBottom]: showBorderBottom },
          { [styles.roundedCorners]: roundedCorners },
          { [styles.smallCorners]: smallCorners },
          { [styles.folderBackground]: isFolderBackground },
        ])}
        style={{ backgroundColor: contentBackgroundColor }}
      >
        {children}
      </div>
    </div>
  );
}

export default FolderTabContainer;
