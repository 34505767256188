const QuizEquityLoanIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="57"
    viewBox="0 0 48 42"
    fill="none"
  >
    <path
      d="M46.7301 19.6417C46.9401 18.6117 47.0101 17.6317 46.9401 16.7717C46.9401 16.7017 46.9401 16.6317 46.9301 16.5617C46.9301 16.3617 46.9101 16.1617 46.8901 15.9617C46.8601 15.5517 46.8001 15.1417 46.7201 14.7317C46.6901 14.5617 46.6601 14.3917 46.6201 14.2217C46.5701 14.0017 46.5201 13.7717 46.4601 13.5617C46.4201 13.4117 46.3801 13.2617 46.3301 13.1117C46.2601 12.8917 46.2001 12.6917 46.1301 12.4817C46.0001 12.1517 45.8701 11.8217 45.7301 11.5017C45.6501 11.3317 45.5701 11.1617 45.4801 10.9817C45.2301 10.4817 44.9401 9.99169 44.6401 9.52169C44.4301 9.21169 44.2101 8.91169 43.9801 8.61169C43.7001 8.25169 43.4001 7.89169 43.0801 7.56169C41.7601 6.17169 40.1801 5.05169 38.4001 4.26169C38.0901 4.12169 37.7801 4.00169 37.4601 3.87169C37.2901 3.81169 37.1301 3.76169 36.9501 3.70169C36.0701 3.41169 35.1501 3.21169 34.2101 3.10169C33.9601 3.06169 33.7201 3.05169 33.4801 3.02169C32.9001 2.98169 32.3201 2.97169 31.7201 2.99169"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.8301 1.27173L29.8301 2.85173L32.7201 4.60173"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.16008 22.7317C7.95008 23.7617 7.88008 24.7417 7.95008 25.6017C7.95008 25.6717 7.95008 25.7417 7.96008 25.8117C7.96008 26.0117 7.98008 26.2117 8.00008 26.4117C8.03008 26.8217 8.09008 27.2317 8.17008 27.6417C8.20008 27.8117 8.23008 27.9817 8.27008 28.1517C8.32008 28.3717 8.37008 28.6017 8.43008 28.8117C8.47008 28.9617 8.51008 29.1117 8.56008 29.2617C8.63008 29.4817 8.69008 29.6817 8.76008 29.8917C8.89008 30.2217 9.02008 30.5617 9.16008 30.8717C9.24008 31.0417 9.32008 31.2117 9.41008 31.3917C9.66008 31.8917 9.95008 32.3917 10.2501 32.8517C10.4601 33.1617 10.6801 33.4617 10.9101 33.7617C11.1901 34.1217 11.4901 34.4817 11.8101 34.8117C13.1301 36.2017 14.7101 37.3317 16.4901 38.1217C16.8001 38.2617 17.1101 38.3817 17.4301 38.5117C17.6001 38.5717 17.7601 38.6217 17.9401 38.6817C18.8201 38.9717 19.7401 39.1717 20.6801 39.2917C20.9301 39.3317 21.1701 39.3417 21.4101 39.3717C21.9901 39.4117 22.5701 39.4217 23.1701 39.4017"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0601 41.1117L25.0601 39.5317L22.1601 37.7817"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.4501 40.7417C42.7078 40.7417 46.9701 36.4794 46.9701 31.2217C46.9701 25.9639 42.7078 21.7017 37.4501 21.7017C32.1923 21.7017 27.9301 25.9639 27.9301 31.2217C27.9301 36.4794 32.1923 40.7417 37.4501 40.7417Z"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.03 33.1117C35.03 34.1517 36.07 35.0017 37.38 35.0017C38.69 35.0017 39.73 34.1517 39.73 33.1017C39.73 32.0517 38.69 31.2117 37.38 31.2117C36.07 31.2117 35.03 30.3617 35.03 29.3217C35.03 28.2817 36.09 27.4317 37.38 27.4217C38.67 27.4117 39.73 28.2717 39.73 29.3117"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.39 25.9917L37.38 36.4517"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.28 11.8018C11.28 12.8418 12.32 13.6918 13.63 13.6918C14.94 13.6918 15.98 12.8418 15.98 11.7918C15.98 10.7418 14.94 9.90178 13.63 9.90178C12.32 9.90178 11.28 9.05178 11.28 8.01178C11.28 6.97178 12.34 6.12178 13.63 6.11178C14.92 6.10178 15.98 6.96178 15.98 8.00178"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.65 4.69177L13.63 15.1418"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.21024 1.27173H25.0502C25.5802 1.27173 26.0102 1.70173 26.0102 2.23173V17.4617C26.0102 17.9917 25.5802 18.4217 25.0502 18.4217H2.21024C1.68024 18.4217 1.25024 17.9917 1.25024 17.4617V2.23173C1.25024 1.70173 1.68024 1.27173 2.21024 1.27173H4.21024Z"
      stroke="#008254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default QuizEquityLoanIcon;