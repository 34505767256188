import React, { useState, ReactNode } from 'react';
import Image from 'next/image';
import { CenteredContainer } from '@nerdwallet/currency';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import QuizResetIcon from './quiz-reset-icon';
import QuizButton from './quiz-button';
import QuizAutoInsuranceIcon from './quiz-auto-insurance-icon';
import QuizLifeInsuranceIcon from './quiz-life-insurance-icon';
import QuizPropertyInsuranceIcon from './quiz-property-insurance-icon';
import styles from './quiz-style.module.scss';
import { INSURANCE_LINKS, QUIZ_LINKS, MORTGAGE_LINKS } from './quiz-data';
import QuizPurchaseIcon from './quiz-purchase-icon';
import QuizEquityLoanIcon from './quiz-home-equity-loan-icon';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '../../../lib/segment/useAnalytics';
import QuizHelocIcon from './quiz-heloc-icon';
import { BASE_IMAGE_URL } from '../../front-page-redesign/constants';

const TABS = {
  INITIAL_TAB: 0,
  INSURANCE_TAB: 1,
  MORTGAGE_TAB: 2,
};

type TabProps = {
  quizTitle: string;
  quizSubTitle: string;
  handleOpenLink?: (link: string) => void;
  handleChangeTab?: (tab: number) => void;
};

function QuizTitle({ children }: { children: ReactNode }): JSX.Element {
  return <h5 className={styles.title}>{children}</h5>;
}

function QuizSubTitle({ children }: { children: ReactNode }): JSX.Element {
  return <p className={styles.subtitle}>{children}</p>;
}

function InitialTab({
  quizTitle,
  quizSubTitle,
  handleOpenLink = () => {},
  handleChangeTab = () => {},
}: TabProps): JSX.Element {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleLinkAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
    });
  };

  const handleInsuranceAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_in_auto',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_in_life',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_in_property',
    });
  };

  const handleMortgageAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_mr_purchase',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_mr_refi',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: 'hp_shop_module_mr_equity',
    });
  };

  return (
    <div className={styles.cardContainer}>
      <QuizTitle>{quizTitle}</QuizTitle>
      <QuizSubTitle>{quizSubTitle}</QuizSubTitle>
      <div className={styles.buttonsContainer}>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_shop_module_cc',
          }}
        >
          <QuizButton
            text="Credit card"
            onClick={() => {
              handleLinkAnalytics('hp_shop_module_cc_click');
              handleOpenLink(QUIZ_LINKS.CREDIT_CARD);
            }}
          />
        </SegmentImpression>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_shop_module_in',
          }}
        >
          <QuizButton
            text="Insurance"
            onClick={() => {
              handleInsuranceAnalytics('hp_shop_module_in_click');
              handleChangeTab(TABS.INSURANCE_TAB);
            }}
          />
        </SegmentImpression>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_shop_module_pl',
          }}
        >
          <QuizButton
            text="Personal loan"
            onClick={() => {
              handleLinkAnalytics('hp_shop_module_pl_click');
              handleOpenLink(QUIZ_LINKS.PERSONAL_LOAN);
            }}
          />
        </SegmentImpression>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_shop_module_mr',
          }}
        >
          <QuizButton
            text="Mortgage"
            onClick={() => {
              handleMortgageAnalytics('hp_shop_module_mr_click');
              handleChangeTab(TABS.MORTGAGE_TAB);
            }}
          />
        </SegmentImpression>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: 'hp_shop_module_smb',
          }}
        >
          <QuizButton
            text="Small business loan"
            onClick={() => {
              handleLinkAnalytics('hp_shop_module_smb_click');
              handleOpenLink(QUIZ_LINKS.SMALL_BUSINESS_LOAN);
            }}
          />
        </SegmentImpression>
      </div>
    </div>
  );
}

function InsuranceTab({
  quizTitle,
  quizSubTitle,
  handleOpenLink = () => {},
}: TabProps): JSX.Element {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
    });
  };

  return (
    <div className={styles.cardContainer}>
      <QuizTitle>{quizTitle}</QuizTitle>
      <QuizSubTitle>{quizSubTitle}</QuizSubTitle>
      <div className={styles.buttonsSecondStepContainer}>
        <QuizButton
          text="Auto"
          icon={() => <QuizAutoInsuranceIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_in_auto_click');
            handleOpenLink(INSURANCE_LINKS.AUTO);
          }}
        />
        <QuizButton
          text="Life"
          icon={() => <QuizLifeInsuranceIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_in_life_click');
            handleOpenLink(INSURANCE_LINKS.LIFE);
          }}
        />
        <QuizButton
          text="Property"
          icon={() => <QuizPropertyInsuranceIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_in_property_click');
            handleOpenLink(INSURANCE_LINKS.PROPERTY);
          }}
        />
      </div>
    </div>
  );
}

function MortgageTab({
  quizTitle,
  quizSubTitle,
  handleOpenLink = () => {},
}: TabProps): JSX.Element {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleAnalytics = (entityName: string) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
    });
  };

  return (
    <div className={styles.cardContainer}>
      <QuizTitle>{quizTitle}</QuizTitle>
      <QuizSubTitle>{quizSubTitle}</QuizSubTitle>
      <div className={styles.buttonsSecondStepContainer}>
        <QuizButton
          text="Purchase"
          icon={() => <QuizPurchaseIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_mr_purchase_click');
            handleOpenLink(MORTGAGE_LINKS.PURCHASE);
          }}
        />
        <QuizButton
          text="Refinance"
          icon={() => <QuizEquityLoanIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_mr_refi_click');
            handleOpenLink(MORTGAGE_LINKS.REFINANCE);
          }}
        />
        <QuizButton
          text="Home equity loan"
          icon={() => <QuizHelocIcon />}
          onClick={() => {
            handleAnalytics('hp_shop_module_mr_equity_click');
            handleOpenLink(MORTGAGE_LINKS.HOME_EQUITY_LOAN);
          }}
        />
      </div>
    </div>
  );
}

function getTabContent(tab: number) {
  const tabMap = {
    [TABS.INITIAL_TAB]: InitialTab,
    [TABS.INSURANCE_TAB]: InsuranceTab,
    [TABS.MORTGAGE_TAB]: MortgageTab,
  };
  return tabMap[tab];
}

type QuizProps = {
  isDirectionTwo?: boolean;
};

const Quiz = ({ isDirectionTwo = false }: QuizProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const quizTitle = isDirectionTwo
    ? 'Answer a few questions, get customized results. Genius'
    : 'Answer a few questions. Get personalized results in minutes.';
  const quizSubTitle = isDirectionTwo
    ? 'What product are youuuuu looking for?'
    : 'What kind of product are you looking for?';

  function handleChangeTab(tab: number) {
    setActiveTab(tab);
  }

  function handleOpenLink(link = '') {
    if (!link) return;

    const newWindow = window.open(link, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  }

  function resetQuiz() {
    handleChangeTab(TABS.INITIAL_TAB);
  }

  return (
    <div className={styles.quizSection}>
      <div className={styles.desktopQuiz}>
        <div className={styles.gridPattern} />
        <CenteredContainer>
          {isDirectionTwo ? (
            <div className={styles.belugaContainerDesktop}>
              <Image
                src={`${BASE_IMAGE_URL}/Beluga_Direction-2@3x.png`}
                alt="beluga"
                width={150}
                height={115}
              />
            </div>
          ) : null}
          <FolderTabContainer
            tabBackgroundColor="#e3faf2"
            contentBackgroundColor="#e3faf2"
            roundedCorners
            showBorderBottom
          >
            {activeTab !== TABS.INITIAL_TAB && (
              <QuizResetIcon
                className={styles.quizResetIcon}
                onClick={resetQuiz}
              />
            )}
            {getTabContent(activeTab)({
              quizTitle,
              quizSubTitle,
              handleOpenLink,
              handleChangeTab,
            })}
          </FolderTabContainer>
        </CenteredContainer>
      </div>

      <div className={styles.mobileQuiz}>
        {isDirectionTwo ? (
          <div className={styles.belugaContainerMobile}>
            <Image
              src={`${BASE_IMAGE_URL}/Beluga_Direction-2@3x.png`}
              alt="beluga"
              width={150}
              height={115}
            />
          </div>
        ) : null}
        <FolderTabContainer
          contentClassName={styles.container}
          tabBackgroundColor="#006642"
          contentBackgroundColor="#006642"
          negativeTopMarginValue={30}
        >
          {activeTab !== TABS.INITIAL_TAB && (
            <QuizResetIcon
              className={styles.quizResetIcon}
              onClick={resetQuiz}
            />
          )}
          {getTabContent(activeTab)({
            quizTitle,
            quizSubTitle,
            handleOpenLink,
            handleChangeTab,
          })}
        </FolderTabContainer>
      </div>
    </div>
  );
};

export default Quiz;
